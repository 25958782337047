var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "weatherBox" }, [
    _c("div", { staticClass: "titleBox" }, [
      _c("div", { staticClass: "dateBox" }, [
        _c("div", [
          _c("span", { staticClass: "year" }, [
            _vm._v(_vm._s(_vm.todayDate.year))
          ])
        ]),
        _c("div", [
          _c("span", { staticClass: "date" }, [
            _vm._v(_vm._s(_vm.todayDate.date))
          ]),
          _c("span", { staticClass: "week" }, [
            _vm._v(_vm._s(_vm.todayDate.dayOfTheWeek))
          ])
        ])
      ]),
      _c("div", { staticClass: "clockBox" }, [
        _vm._m(0),
        _c("span", [_vm._v(_vm._s(_vm.todayDate.clock))])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("img", { attrs: { src: "/images/icon-clock.png" } })])
  }
]
render._withStripped = true

export { render, staticRenderFns }