<template>
	<div class="weatherBox">
		<div class="titleBox">
			<div class="dateBox">
				<div>
					<span class="year">{{ todayDate.year }}</span>
				</div>
				<div>
					<span class="date">{{ todayDate.date }}</span>
					<span class="week">{{ todayDate.dayOfTheWeek }}</span>
				</div>
			</div>
			<div class="clockBox">
				<div><img src="/images/icon-clock.png" /></div>
				<span>{{ todayDate.clock }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		loginUserSiteNm: { type: String, required: false, default: '현재날씨' },
	},
	mounted() {
		this.init();
	},
	data: () => ({
		weekDayStrArr: ['일', '월', '화', '수', '목', '금', '토'],
		dateWeek: [],
		today: { weatherIcon: 'fa-cloud-sun' },
		// 현재시각 표시
		todayDate: {
			year: '',
			date: '',
			dayOfTheWeek: '',
			clock: '',
		},
	}),
	methods: {
		addZero(string) {
			// 값이 1자리면 앞에 '0' 붙임
			return string[1] ? string : '0' + string[0];
		},
		clock() {
			// 현재 시각
			const date = new Date();
			const yyyy = date.getFullYear().toString();
			const mm = (date.getMonth() + 1).toString();
			const dd = date.getDate().toString();
			const h = date.getHours().toString();
			const m = date.getMinutes().toString();
			const s = date.getSeconds().toString();
			const week = ['일', '월', '화', '수', '목', '금', '토'];

			this.todayDate = {
				year: yyyy,
				date: this.addZero(mm) + '.' + this.addZero(dd),
				dayOfTheWeek: week[date.getDay()] + '요일',
				clock: this.addZero(h) + ':' + this.addZero(m) + ':' + this.addZero(s),
			};

			// 30초마다 reload?
			if (this.tick++ % 5 !== 0) return;

			this.tick = 1;
		},
		init() {
			this.clock(); // 딜레이 없이 바로 표시하기 위함
			setInterval(() => {
				this.clock();
			}, 1000); // 1초 마다 시간 변경
		},
	},
};
</script>

<style>
.weatherBox {
	width: 100%;

	box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}
.weatherBox > .titleBox {
	display: flex;
	height: 70px;
	padding: 10px 0 0 10px;
	background: #fafafa;
	font-weight: bold;
}
.titleBox > .dateBox {
	width: 45%;
	padding-left: 10px;
}
.dateBox > div {
	margin-bottom: -15px;
}
.dateBox > div > .year {
	font-size: 1em;
	margin-right: 10px;
}
.dateBox > div > .date {
	font-size: 1.5em;
	margin-right: 5px;
}
.titleBox > .clockBox {
	width: 50%;
	padding-left: 10px;
	display: flex;
	align-items: center;
}
.clockBox > div {
	display: inline-block;
}
.clockBox > div > img {
	width: 40px;
}
.clockBox > span {
	float: right;
	font-size: 1.8em;
	margin-top: -5px;
}
.weatherBox > .contentBox {
	padding: 5px 15px;
	font-weight: bold;
}
.weatherBox > .contentBox > div {
	width: 100%;
	display: flex;
	padding-bottom: 5px;
	border-bottom: 1px solid #ddd;
}

.current-weather-icon {
	margin: 5px -5px 0;
	font-size: 3.5em !important;
	margin-right: 5px;
}
.current-temp {
	width: 130px;
	font-size: 2.8em;
	text-align: center;
	margin-top: -5px;
	margin-right: 5px;
}
.current-weather-info {
	display: block;
	width: 80px;
	font-size: 9pt;
	line-height: 2em;
	overflow: hidden;
	white-space: nowrap;
}
.week-temp {
	display: flex;
	width: 33.3%;
	height: 100%;
	text-align: center;
	white-space: nowrap;
}
.week-temp > div {
	width: 33.3%;
}
.week-temp:not(:last-child) {
	border-right: 1px solid #ddd;
}
.week-date {
	font-weight: lighter;
	font-size: 0.8em;
}
.week-weather-icon {
	font-size: 2em;
}
</style>
