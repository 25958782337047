var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "flex-direction": "row",
        "flex-wrap": "wrap"
      },
      attrs: { id: "rootVM" }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            width: "1098px"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                width: "1098px"
              }
            },
            [
              _vm.dangerOccurRealtime != null
                ? _c(
                    "div",
                    {
                      staticClass: "boxBorder",
                      staticStyle: {
                        width: "1080px",
                        height: "430px",
                        "margin-right": "10px"
                      }
                    },
                    [
                      _c("div", { staticStyle: {} }, [
                        _c(
                          "div",
                          {
                            staticClass: "contentBox",
                            staticStyle: { height: "100%", display: "flex" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { width: "720px", height: "428px" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      width: "100%",
                                      height: "100%",
                                      "text-align": "center",
                                      float: "left",
                                      "margin-right": "20px"
                                    },
                                    on: { click: _vm.curEventFrames }
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                        "object-fit": "unset"
                                      },
                                      attrs: {
                                        src: _vm.dangerOccurRealtimeImgSrc
                                      }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _c("div", { staticStyle: { width: "360px" } }, [
                              _vm._m(0),
                              _c("div", { staticClass: "boxBorder" }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "350px",
                                      height: "238px",
                                      "margin-left": "10px",
                                      overflow: "hidden"
                                    }
                                  },
                                  [
                                    _c("br"),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#008cd6",
                                          "font-size": "23px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dangerOccurRealtime
                                              .dangerOccurDtm
                                          )
                                        )
                                      ]
                                    ),
                                    _c("br"),
                                    _c("br"),
                                    _c("div", { staticClass: "rt-dvsn" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.dangerOccurRealtime
                                              .videoDangerActnDvsnNm
                                          ) +
                                          " "
                                      )
                                    ]),
                                    _c("div", { staticClass: "rt-row" }, [
                                      _vm._m(1),
                                      _c("div", { staticClass: "rt-row-ct" }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dangerOccurRealtime.siteNm
                                            )
                                          )
                                        ])
                                      ])
                                    ]),
                                    _c("div", { staticClass: "rt-row" }, [
                                      _vm._m(2),
                                      _c("div", { staticClass: "rt-row-ct" }, [
                                        _c("span", [
                                          _vm._v(
                                            "[" +
                                              _vm._s(
                                                _vm.dangerOccurRealtime.cctvNm
                                              ) +
                                              "]"
                                          )
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dangerOccurRealtime
                                                .cctvInstallPlaceCont
                                            )
                                          )
                                        ])
                                      ])
                                    ]),
                                    _c("div", { staticClass: "rt-row" }, [
                                      _vm._m(3),
                                      _c("div", { staticClass: "rt-row-ct" }, [
                                        _c("span", [
                                          _vm._v(
                                            "[" +
                                              _vm._s(
                                                _vm.dangerOccurRealtime.objectNm
                                              ) +
                                              "]"
                                          )
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dangerOccurRealtime.targetNm
                                            )
                                          )
                                        ])
                                      ])
                                    ])
                                  ]
                                ),
                                _c("div", { attrs: { process: "" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "boxBorder",
                                      staticStyle: {
                                        cursor: "pointer",
                                        "margin-top": "49px",
                                        padding: "0px",
                                        "font-size": "15px",
                                        display: "flex",
                                        width: "360px",
                                        height: "91px"
                                      },
                                      attrs: { id: "bd" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click:
                                              _vm.clickedRedirectionDangerOccur
                                          }
                                        },
                                        [
                                          _vm._m(4),
                                          _c("div", [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "#ff0000"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm
                                                      .currentWarnWorkDangerOccur
                                                      .dangerOccurCnt
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" 건 ")
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click:
                                              _vm.clickedRedirectionDangerOccurTakingAction
                                          }
                                        },
                                        [
                                          _vm._m(5),
                                          _c("div", [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "#5bc0de"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm
                                                      .currentWarnWorkDangerOccur
                                                      .takingActionCnt
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" 건 ")
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.clickedCnt("actn")
                                            }
                                          }
                                        },
                                        [
                                          _vm._m(6),
                                          _c("div", [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "#00b050"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm
                                                      .currentAccidentPreventItem
                                                      .actnCnt
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" 건 ")
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ])
                          ]
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.dangerOccurRealtime == null
                ? _c(
                    "div",
                    {
                      staticClass: "boxBorder",
                      staticStyle: {
                        width: "1080px",
                        height: "430px",
                        "margin-right": "10px"
                      }
                    },
                    [
                      _c("div", { staticClass: "titleBox" }, [
                        _vm._v("최근 위험 발생")
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            width: "100%",
                            height: "380px",
                            "justify-content": "center",
                            "padding-top": "35px"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "contentBox",
                              staticStyle: { height: "260px" }
                            },
                            [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _vm._m(7),
                                _c(
                                  "div",
                                  { staticStyle: { "padding-top": "90px" } },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.nonDangerOccurResultStr)
                                      )
                                    ])
                                  ]
                                )
                              ]),
                              _c("div", { attrs: { process: "" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "boxBorder",
                                    staticStyle: {
                                      cursor: "pointer",
                                      "margin-top": "10px",
                                      padding: "0px",
                                      "font-size": "15px",
                                      display: "flex",
                                      height: "91px"
                                    },
                                    attrs: { id: "bd" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click:
                                            _vm.clickedRedirectionDangerOccur
                                        }
                                      },
                                      [
                                        _vm._m(8),
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#ff0000" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.currentWarnWorkDangerOccur
                                                    .dangerOccurCnt
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" 건 ")
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click:
                                            _vm.clickedRedirectionDangerOccurTakingAction
                                        }
                                      },
                                      [
                                        _vm._m(9),
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#5bc0de" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.currentWarnWorkDangerOccur
                                                    .takingActionCnt
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" 건 ")
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.clickedCnt("actn")
                                          }
                                        }
                                      },
                                      [
                                        _vm._m(10),
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#00b050" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.currentAccidentPreventItem
                                                    .actnCnt
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" 건 ")
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _c("div", { attrs: { notice: "" } }, [
                _c(
                  "div",
                  {
                    staticClass: "boxBorder",
                    staticStyle: {
                      height: "50px",
                      "margin-bottom": "10px",
                      "margin-right": "10px"
                    }
                  },
                  [
                    _vm._m(11),
                    _c(
                      "div",
                      { staticStyle: { width: "80%" } },
                      _vm._l(_vm.noticeInfo, function(notice, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticStyle: { width: "100%" },
                            on: {
                              click: function($event) {
                                return _vm.showNoticeDetail(index)
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  width: "50%",
                                  "text-decoration": "underline"
                                }
                              },
                              [_vm._v(_vm._s(notice.noticeTitle))]
                            ),
                            notice.oriFileNm != ""
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      display: "inline-block",
                                      width: "25%",
                                      "text-align": "right"
                                    }
                                  },
                                  [_c("i", { staticClass: "icon-attachment" })]
                                )
                              : _vm._e(),
                            notice.oriFileNm == ""
                              ? _c("span", {
                                  staticStyle: {
                                    display: "inline-block",
                                    width: "25%",
                                    "text-align": "right"
                                  }
                                })
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  width: "12%",
                                  "text-align": "center"
                                }
                              },
                              [_vm._v(_vm._s(notice.regUser))]
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  width: "12%",
                                  "text-align": "center"
                                }
                              },
                              [_vm._v(_vm._s(notice.regDtm))]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "RFlex",
              staticStyle: { display: "flex", "flex-direction": "column" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "boxBorder",
                  staticStyle: {
                    width: "350px",
                    height: "73px",
                    "margin-bottom": "10px",
                    "margin-right": "10px"
                  }
                },
                [
                  _c(
                    "div",
                    { attrs: { id: "ClockAndDate" } },
                    [
                      _c("clock-and-date", {
                        attrs: { loginUserSiteNm: _vm.loginUserSiteNm }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "boxBorder",
                  staticStyle: { width: "100%", height: "407px" }
                },
                [
                  _c("div", { staticClass: "titleBox" }, [
                    _vm._v(" 발생 위험유형 "),
                    _c("span", { staticStyle: { "font-size": "13px" } }, [
                      _vm._v(
                        _vm._s(_vm.objectTargetDangerOccurPeriod.start) +
                          " ~ " +
                          _vm._s(_vm.objectTargetDangerOccurPeriod.end)
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "contentBox object-danger-content",
                      staticStyle: { padding: "8px" }
                    },
                    _vm._l(
                      _vm.objectTargetDangerOccur.objectTargetDangerOccur,
                      function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "object-danger" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "object-danger-row",
                                class: item.classList
                              },
                              [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(_vm._s(item.objectNm))
                                ]),
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(_vm._s(item.targetNm))
                                ]),
                                _c("div", { staticClass: "value" }, [
                                  item.percent != undefined
                                    ? _c("span", { staticClass: "percent" }, [
                                        _vm._v(_vm._s(item.percent) + " / ")
                                      ])
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(_vm._s(item.objectTargetDangerCnt))
                                  ])
                                ])
                              ]
                            ),
                            _c("table", { attrs: { bar: "" } }, [
                              _c("tr", [
                                _c("td", {
                                  class: item.classList,
                                  style: item.tdWidth
                                }),
                                _c("td")
                              ])
                            ])
                          ]
                        )
                      }
                    ),
                    0
                  )
                ]
              )
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            width: "100%"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "boxBorder",
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "padding-top": "0px",
                "padding-bottom": "0px",
                width: "50%",
                height: "300px",
                "margin-right": "10px"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "titleBox",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "padding-left": "0",
                    "padding-top": "0"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-weight": "bold",
                        height: "48px",
                        padding: "10px"
                      }
                    },
                    [_vm._v("현황")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "btn-group",
                      staticStyle: { "margin-left": "auto", padding: "0 10px" }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          class: _vm.chartClickClass[0],
                          staticStyle: {
                            width: "50px",
                            height: "28px",
                            padding: "0px"
                          },
                          attrs: { type: "button" },
                          on: { click: _vm.loadDayChart }
                        },
                        [_vm._v(" 일 ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          class: _vm.chartClickClass[1],
                          staticStyle: {
                            width: "50px",
                            height: "28px",
                            padding: "0px"
                          },
                          attrs: { type: "button" },
                          on: { click: _vm.loadWeekChart }
                        },
                        [_vm._v(" 주 ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          class: _vm.chartClickClass[2],
                          staticStyle: {
                            width: "50px",
                            height: "28px",
                            padding: "0px"
                          },
                          attrs: { type: "button" },
                          on: { click: _vm.loadMonthChart }
                        },
                        [_vm._v(" 월 ")]
                      )
                    ]
                  )
                ]
              ),
              _vm._m(12)
            ]
          ),
          _c(
            "div",
            {
              staticClass: "boxBorder",
              staticStyle: { width: "50%", height: "300px" }
            },
            [
              _c("div", { staticClass: "titleBox" }, [_vm._v("위험 보고")]),
              _c(
                "div",
                { staticClass: "contentBox", staticStyle: { height: "340px" } },
                [
                  _c(
                    "table",
                    [
                      _c("tr", [
                        _c("th", { staticStyle: { width: "25%" } }, [
                          _vm._v(_vm._s(_vm.dangerOccurTable.siteCctvNm))
                        ]),
                        _c("th", { staticStyle: { width: "20%" } }, [
                          _vm._v(_vm._s(_vm.dangerOccurTable.dangerOccurDtm))
                        ]),
                        _c("th", { staticStyle: { width: "17%" } }, [
                          _vm._v(_vm._s(_vm.dangerOccurTable.objectNm))
                        ]),
                        _c("th", { staticStyle: { width: "17%" } }, [
                          _vm._v(_vm._s(_vm.dangerOccurTable.targetNm))
                        ]),
                        _c("th", { staticStyle: { width: "16%" } }, [
                          _vm._v(
                            _vm._s(_vm.dangerOccurTable.videoDangerActnDvsnNm)
                          )
                        ])
                      ]),
                      _vm._l(_vm.dangerOccurReport, function(item, index) {
                        return _c(
                          "tr",
                          {
                            key: index,
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                return _vm.showDangerReportDetail(item)
                              }
                            }
                          },
                          [
                            _c("td", [
                              _c("span", [
                                _vm._v("[" + _vm._s(item.siteNm) + "] ")
                              ]),
                              _c("span", [_vm._v(_vm._s(item.cctvNm))])
                            ]),
                            _c("td", [_vm._v(_vm._s(item.dangerOccurDtm))]),
                            _c("td", [_vm._v(_vm._s(item.objectNm))]),
                            _c("td", [_vm._v(_vm._s(item.targetNm))]),
                            _c("td", [
                              _vm._v(_vm._s(item.videoDangerActnDvsnNm))
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                ]
              )
            ]
          )
        ]
      ),
      _c("div", { ref: "popupImg", staticClass: "modal" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c(
            "span",
            { staticClass: "close", on: { click: _vm.popupImgClose } },
            [_vm._v("×")]
          ),
          _c("div", { staticStyle: { height: "30px" } }),
          _c(
            "div",
            {
              staticClass: "row",
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-content": "center"
              }
            },
            [
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      flex: "1",
                      "overflow-y": "auto",
                      "flex-direction": "column",
                      height: "720px",
                      "max-height": "720px",
                      "border-right": "1px solid #ddd",
                      "padding-right": "15px"
                    },
                    attrs: { left: "" }
                  },
                  _vm._l(
                    _vm.dangerOccurDetailsItem.inqDangerOccurFrameImgOutVOs,
                    function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          class: { active: index == _vm.activePicture },
                          staticStyle: {
                            position: "relative",
                            overflow: "hidden"
                          },
                          attrs: { tabindex: index, photo: "" },
                          on: {
                            click: function($event) {
                              return _vm.selectPicture(index)
                            },
                            keydown: [
                              function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k($event.keyCode, "up", 38, $event.key, [
                                    "Up",
                                    "ArrowUp"
                                  ])
                                ) {
                                  return null
                                }
                                return _vm.priviousImage()
                              },
                              function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "left",
                                    37,
                                    $event.key,
                                    ["Left", "ArrowLeft"]
                                  )
                                ) {
                                  return null
                                }
                                if ("button" in $event && $event.button !== 0) {
                                  return null
                                }
                                return _vm.priviousImage()
                              },
                              function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "down",
                                    40,
                                    $event.key,
                                    ["Down", "ArrowDown"]
                                  )
                                ) {
                                  return null
                                }
                                return _vm.nextImage()
                              },
                              function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "right",
                                    39,
                                    $event.key,
                                    ["Right", "ArrowRight"]
                                  )
                                ) {
                                  return null
                                }
                                if ("button" in $event && $event.button !== 2) {
                                  return null
                                }
                                return _vm.nextImage()
                              }
                            ]
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.dangerOccurDetailsItem
                                  .inqDangerOccurFrameImgOutVOs[index].imgData
                            }
                          }),
                          _vm._m(13, true)
                        ]
                      )
                    }
                  ),
                  0
                )
              ]),
              _c(
                "div",
                { staticClass: "col-md-9", attrs: { align: "center" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "5 1 0%",
                        height: "inherit",
                        "justify-content": "center"
                      },
                      attrs: { align: "center", right: "" }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "910px",
                          "max-height": "520px",
                          "object-fit": "contain",
                          display: "block"
                        },
                        attrs: { id: "popupImg", src: this.popupImg.imgData }
                      })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "5 1 0%",
                        "margin-left": "15px",
                        "justify-content": "center"
                      },
                      attrs: { align: "center", right: "" }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "mBox col-md-3" }, [
                          _vm._m(14),
                          _c("div", { staticClass: "border-bottom" }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "10px",
                                "margin-left": "20px",
                                "font-size": "14px"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.dangerOccurDetailsItem.cctvNm) +
                                  " - " +
                                  _vm._s(
                                    _vm.dangerOccurDetailsItem
                                      .cctvInstallPlaceCont
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "10px",
                                "margin-left": "20px",
                                "font-size": "14px"
                              }
                            },
                            [_vm._v(_vm._s(_vm.popupImg.imgSaveDtm))]
                          )
                        ]),
                        _c("div", { staticClass: "mBox" }, [
                          _vm._m(15),
                          _c("div", { staticClass: "border-bottom" }),
                          _c(
                            "div",
                            {
                              key: _vm.forRerendering,
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center"
                              }
                            },
                            _vm._l(_vm.popupImg.objects, function(
                              element,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "objects",
                                  staticStyle: {
                                    display: "inline-block",
                                    "max-width": "50%",
                                    margin: "10px"
                                  }
                                },
                                _vm._l(element, function(objectAndTarget, i) {
                                  return _c(
                                    "div",
                                    {
                                      key: i,
                                      staticStyle: {
                                        "font-size": "14px",
                                        "text-align": "center"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(objectAndTarget) + " "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            }),
                            0
                          )
                        ])
                      ])
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "boxBorder" }, [
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            height: "48px",
            padding: "10px 0 0 10px",
            background: "#fafafa",
            "font-weight": "bold"
          }
        },
        [_vm._v("최근 위험 발생")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rt-row-tt" }, [
      _c("span", [_vm._v("○ 현 장 명:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rt-row-tt" }, [
      _c("span", [_vm._v("○ C C T V:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rt-row-tt" }, [
      _c("span", [_vm._v("○조치내용:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/images/delay.png" } }),
      _vm._v(" 위험발생 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/images/review.png" } }),
      _vm._v(" 조치 중 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/images/end.png" } }),
      _vm._v(" 완료 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: { src: "/images/icon-no-result.png", "no-result": "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/images/delay.png" } }),
      _vm._v(" 위험발생 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/images/review.png" } }),
      _vm._v(" 조치 중 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/images/end.png" } }),
      _vm._v(" 완료 ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "nt-guide",
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "align-items": "center"
        }
      },
      [
        _c("span", { staticStyle: { display: "inline-block" } }, [
          _vm._v("최신 공지사항")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          flex: "5",
          margin: "3px auto 0 auto",
          width: "100%",
          padding: "0"
        }
      },
      [
        _c("div", {
          staticStyle: { height: "100%" },
          attrs: { id: "lineChart" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", {
        staticStyle: {
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h4", [_vm._v("장소 및 시각")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h4", [_vm._v("조치 대상")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }